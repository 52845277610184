// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
$header-height: 50px;
// $Menu-width: 250px;
$Menu-width: 230px;
$Menu-collapsed-width: 70px;

// color list for build layouts
$primary-color: #4680ff;
$warning-color: #ffba57;
$danger-color: #ff5252;
$success-color: #9ccc65;
$purple-color: #536dfe;
$info-color: #00bcd4;
$secondary-color: #748892;
$default-color: #d6d6d6;
$light-color: #ECEFF1;
$dark-color: #263238;
$inverse-color: $dark-color;
$theme-border: #e2e5e8;

// header
$header-dark-background: #101b33;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #101b33;

// Menu
$menu-dark-background: #101b33;
$menu-dark-text-color: #97a7c1;

$menu-light-background: #fff;
$menu-light-text-color: #535763;
$menu-active-color: $primary-color;

$dark-layout: darken(#101b33,8%);
$dark-layout-font: #adb7be;


// Menu icon
$menu-icon-color: #4680ff, #ff5252, #01a9ac ,#536dfe , #00bcd4, $warning-color;

// Header background
$color-header-name: blue, red, purple, info, green, dark;
$color-header-color: $primary-color, $danger-color, $purple-color ,$info-color, $success-color,#101b33;
// Header gradient
$color-header-grd-name: grd-blue, grd-red, grd-purple, grd-info, grd-green, grd-dark;
$color-header-grd-color: linear-gradient(to right, $primary-color 0%, #00f2fe 100%),
linear-gradient(to right, $danger-color 0%, #ff9a44 100%),
linear-gradient(to right, $purple-color 0%, #00adff 100%),
linear-gradient(to right, $info-color 0%, #22e1ff 100%),
linear-gradient(to right, $success-color 0%, #00e3ae 100%),
linear-gradient(to right, #101b33 0%, #1f3054 100%);


// Active background color
$color-active-name: blue, red, purple, info, dark;
$color-active-color: #4680ff, #ff5252, #536dfe ,#00bcd4,$dark-color;

 // Menu title color
$color-title-name: blue, red, purple, info, dark;
$color-title-color: #4680ff, #ff5252, #536dfe ,#00bcd4,$dark-color;

$theme-font-family: 'Open Sans', sans-serif;
$theme-font-size: 14px;
$theme-background: #ecf0f5;
$theme-font-color: #373a3c;
$theme-heading-color: #37474f;

$color-name: c-blue,c-red,c-green,c-yellow,c-purple;
$color-color: $primary-color,$danger-color,$success-color ,$warning-color ,$purple-color;
$color-bt-name: primary,danger,success,warning,info,purple;
$color-bt-color: $primary-color,$danger-color,$success-color ,$warning-color ,$info-color,$purple-color;

// form-control variables
$form-bg: #ecf0f5;

// social color variables
$social-name: facebook,twitter,dribbble,pinterest,youtube,googleplus,instagram,viber,behance,dropbox,linkedin;
$social-color: #3C5A99,#42C0FB,#EC4A89,#BF2131,#E0291D,#C73E2E,#AA7C62,#7B519D,#0057ff,#3380FF,#0077B5;

// =====================================================================
// ==================   Bootstrap Extra Variable  ======================
// =====================================================================

// Color contrast
$yiq-contrasted-threshold: 200;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8%;
$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// card block css
$card-shadow: 0 2px 10px -1px rgba(69,90,100,0.3);
$card-header-border:1px solid #e2e5e8;
