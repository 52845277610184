.css-1zr2ch, .css-qr4oio, .css-tjro54, .css-malosm, .css-okqsy5, .css-jhom75, .css-1m0gq0n,
.css-1u9cqoz, .css-1p3cd4g, .css-wv31tw, .css-aflw9m, .css-p6rgdl, .css-1qq6kh8, .css-1llmwb {
  overflow: visible !important;
  margin: 20px !important;
  z-index: 1029;

  .react-reveal{
    width: 100%;
    .alert{
      width: auto;
    }
  }
}

.alert-inverse {
  background-color: #333;
  color: #fff;
  .close:hover {
    color: #fff;
  }
}
.alert-dismissible{
  .close{
    padding: 0px !important;
  }
}