@media (max-width: 1380px) {
    .edit_profile .profile-avatar {
        width: 33%;
        padding: 10px;
    }
}

@media (max-width: 1199px) {
    .edit_profile .profile-avatar-wrapper {
        max-width: 160px;
    }

    .edit_profile .profile-avatar {
        width: 50%;
        padding: 10px;
    }

    .resp_overview .social-card h2 {
        font-size: 20px;
    }

    .resp_overview .social-card i {
        font-size: 25px;
    }

    .custom_hashtag_template .custom_h1_hashtag {
        font-size: 70px !important;
    }
    .leaderboard_list_box {
        top: -20px;
    }
}

@media (max-width: 1024px) {
    .chevron-icon .fa {
        top: 20px !important;
        right: 25px !important;
        font-size: 9px;
    }
}

@media (max-width: 991px) {
    .pcoded-header > .collapse:not(.show), .pcoded-header .container > .collapse:not(.show) {
        background: var(--blueJeans) !important;
    }

    .auth-wrapper .auth_logo img {
        width: 150px;
        height: auto;
    }

    .resp_overview .social-card p {
        font-size: 14px;
    }

    .pcoded-header .m-header {
        justify-content: center;
    }
    .reward-text p {
        word-break: break-word;
        padding: 10px 15px 10px 0;
    }
}

@media (max-width: 768px) {
    .mobile-btn-block {
        display: block;
        width: 100%;
    }
    .content-design .integrate-links {
        padding: 10px 15px;
        font-size: 12px;
    }
    .content-design .btn-save-draft,
    .content-design .review,
    .btn-save-draft,
    .btn-edit-preview,
    .btn-download-preview,
    .btn-done-preview {
        padding: 10px 15px;
        font-size: 12px;
    }
    .btn-save-draft svg,
    .content-design .review svg,
    .btn-edit-preview svg,
    .btn-download-preview svg,
    .btn-done-preview svg {
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 767px) {
    .edit_profile .profile-avatar-wrapper {
        max-width: 320px;
    }

    .edit_profile .profile-avatar {
        width: 25%;
        padding: 10px;
    }

    .clients-list .data-table-extensions>.data-table-extensions-filter {
        width: 100%
    }

    .clients-list .data-table-extensions {
        position: initial;
        width: 100%;
        margin-bottom: 15px;
    }

    .clients-list .data-table-extensions>.data-table-extensions-filter>.filter-text {
        width: 100%;
    }

    .data-table-extensions>.data-table-extensions-filter>.icon {
        position: absolute;
        left: 13px;
    }

    .campaign_dashboard .custom_value_influencers {
        margin-right: 0;
    }

    .campaign_dashboard .custom_top_share {
        margin-right: 0;
    }
}

@media (max-width: 576px) {
    .auth-wrapper .auth-content:not(.container) {
        width: auto;
    }

    .campaign-tile {
        height: 130px;
    }

    .pcoded-header .dropdown .profile-notification,
    .pcoded-header .dropdown .notification {
        inset: auto 0 auto 15px !important;
    }

    .mob-padding {
        padding: 5px 15px !important;
    }

    .mob-padding svg {
        width: 15px;
        margin-right: 3px !important;
    }

    .mob-width {
        width: 100%;
    }

    .reports-date {
        font-size: 12px !important;
    }

    .reports .rmdp-input {
        font-size: 11px !important;
        padding: 12px 0 12px 5px !important;
    }

    .pcoded-content {
        padding: 25px 0;
    }

    .page-header {
        padding: 0 15px;
    }

    .mobile-md-btn-block {
        display: block;
        width: 100%;
    }
    .campaign_dashboard .shares_card {
        height: calc(100% - 15px);
    }
}

@media (max-width: 420px) {
    .edit_profile .profile-avatar-wrapper {
        max-width: 240px;
    }

    .edit_profile .profile-avatar {
        width: 33%;
        padding: 10px;
    }

    .campaign-tile {
        height: 100px;
    }
}

@media (max-width: 320px) {
    .reports-date {
        font-size: 9px !important;
    }
    .reports .rmdp-input {
        font-size: 11px !important;
        padding: 12px 0 12px 5px !important;
        margin: 0 3px;
    }
    .report-filter {
        font-size: 7px;
    }
    .report-date-clear {
        font-size: 11px;
    }
    .mobile-md-btn-block {
        display: block;
        width: 100%;
    }
}

/* Media Queries for safari */

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        .cross-browser {
            padding-left: 55px;
        }
    }
}